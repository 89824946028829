export default [
  {
    color: '#e63b4f',
    text: 'statuses.inTreatment',
  },
  {
    color: '#02baef',
    text: 'statuses.inSeparation',
  },
  {
    color: '#98def2',
    text: 'statuses.published',
  },
  {
    color: '#0188af',
    text: 'statuses.waitingForStart',
  },
  {
    color: '#0b4f6c',
    text: 'statuses.inProgress',
  },
  {
    color: '#1cca42',
    text: 'statuses.delivered',
  },
  {
    color: '#e6b93b',
    text: 'statuses.failure',
  },
  {
    color: '#4b4b4d',
    text: 'statuses.canceled',
  },
  {
    color: '#b4b4b8',
    text: 'statuses.others',
  },
];
