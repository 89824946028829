<template>
  <div class="uello-app-map rounded bg-transition h-100 position-relative">
    <transition name="slide-map-change" mode="out-in">
      <div
        class="change-map-style position-absolute rounded p-2 bg-white"
        v-if="showMapStyleCHange"
      >
        <b-form-checkbox
          @change="changeMapStyle"
          key="change-map-style"
          switch
          size="sm"
          class="text-dark-darken font-weight-bold cursor-pointer"
          >Mudar Estilo do mapa</b-form-checkbox
        >
      </div>
    </transition>
    <transition name="slide-left" mode="out-in">
      <map-float-status-info v-if="loaded" :statuses="statuses" />
    </transition>
    <transition name="fade" mode="out-in">
      <loading-overlay v-if="loading"></loading-overlay>
    </transition>
    <map-float-filter
      :filters="selectedFilters"
      :pagination="pagination"
      :statuses="statuses"
      @changeFilter="changeFilter"
      @setPage="getOrders"
    />
    <!-- <map-order-count-per-status :ordersStatusCount="counter" /> -->
    <mgl-map
      :accessToken="mapbox.accessToken"
      :mapStyle="mapbox.mapStyle"
      :center="mapbox.center"
      :zoom="mapbox.zoom"
      class="w-100 h-100"
    >
      <mgl-marker
        v-for="(marker, index) in markers"
        :coordinates="marker.coordinates"
        :key="`marker-${index}`"
      >
        <map-marker slot="marker" :color="marker.color" :orderNumber="marker.routeOrderNumber" />
        <mgl-popup>
          <marker-popup :order="marker" />
        </mgl-popup>
      </mgl-marker>
    </mgl-map>
    <movidesk-modal
      :hasButton="movideskHasButton"
      :is-open="movideskIsOpen"
      :prefill="{}"
      @close="closeMovidesk"
      @open="openMovidesk"
    />
  </div>
</template>

<script>
import { MglMap, MglMarker, MglPopup } from 'vue-mapbox';

import { movidesk } from '@/mixins';
import api from '@/services/api';
import {
  getStateStyle,
  fakeGeocode,
  // getPartner,
  // getUserInfo,
  statusOperationsExtract,
  safeStorage,
} from '@/utils';
import colors from '@/config/statusConfig';

const { mapbox_key } = safeStorage.getItem('config');

export default {
  name: 'uello-app-map',
  mixins: [movidesk],
  components: {
    MglMap,
    MglMarker,
    MglPopup,
    MapFloatFilter: () => import('./components/u-map/MapFloatFilter.vue'),
    MapMarker: () => import('./components/u-map/MapMarker.vue'),
    LoadingOverlay: () => import('./components/u-follow/FollowAgGridLoadingOverlay.vue'),
    MarkerPopup: () => import('./components/u-map/MarkerPopup.vue'),
    MapFloatStatusInfo: () => import('./components/u-map/MapFloatStatusInfo.vue'),
    MovideskModal: () => import('@/components/MovideskTicketFormModal.vue'),
  },
  data() {
    return {
      showMapStyleCHange: false,
      movideskHasButton: true,
      mapbox: {
        accessToken: mapbox_key,
        mapStyle: process.env.VUE_APP_MAPBOX_STYLE,
        center: [-46.6329973, -23.5506117],
        zoom: 10,
      },
      mapboxRef: null,
      markers: [],
      counter: [],
      selectedFilters: [],
      loading: false,
      pagination: {},
      statuses: [],
      loaded: false,
      mapStyleChange: false,
      created_at: null,
    };
  },
  created() {
    this.getOrders();
  },
  watch: {
    selectedFilters(value, oldValue) {
      if (value !== oldValue) {
        this.getOrders();
      }
    },
  },
  computed: {
    getOperationStatuses() {
      const { statuses, operations } = safeStorage.getItem('config');

      const statusTypes = operations.map(status => status.type);
      const partnerStatuses = statusTypes.reduce((acumulator, status) => {
        acumulator = { ...acumulator, ...statuses[status] };
        return acumulator;
      }, {});

      return partnerStatuses;
    },
    getAditionalStatuses() {
      const { operations } = safeStorage.getItem('config');
      const statusTypes = Array.from(new Set(operations.map(operation => operation.type)));
      const aditionalStatus = [];

      statusTypes.forEach(status => {
        switch (status) {
          case 'delivery':
            aditionalStatus.push('entregue');
            break;
          case 'pickup':
            aditionalStatus.push('coletado', 'entregue');
            break;
          case 'spot':
            aditionalStatus.push('entregue', 'coletado');
            break;
          default:
            aditionalStatus.push('entregue', 'coletado');
        }
      });

      return aditionalStatus;
    },
  },
  methods: {
    changeMapStyle(value) {
      this.mapbox = {
        ...this.mapbox,
        mapStyle: value ? 'mapbox://styles/mapbox/dark-v10' : process.env.VUE_APP_MAPBOX_STYLE,
      };
    },
    initMarkers() {
      let master = 0;
      const markers = [];

      for (let i = 0; i < 10; i += 1) {
        const marker = {
          coordinates: fakeGeocode(),
          color: colors[master].color,
          routeOrderNumber: i,
          statusText: colors[master].text,
        };
        markers.push(marker);

        if (i === 9 && master < 5) {
          i = 0;
          master += 1;
        }
      }

      this.markers = markers;
    },
    statusCount() {
      const counter = [];
      colors.forEach(color => {
        const count = this.markers.reduce((acc, item) => {
          if (item.statusText === color.text) {
            acc += 1;
          }
          return acc;
        }, 0);
        if (count > 0) {
          counter.push({ color: color.color, statusText: color.text, count });
        }
      });

      this.counter = counter;
    },
    changeFilter(filters) {
      this.created_at = filters.created_at;
      this.selectedFilters = filters.status;
    },
    async getOrders(page = 1) {
      this.loading = true;

      try {
        const statusCount = {};
        const filters = {};

        if (this.selectedFilters.length) {
          filters.status = this.selectedFilters;
        }

        if (this.created_at) {
          filters.created_at = this.created_at;
        }

        const { data: response } = await api.get(
          `/orders/map?limit=500&page=${page}${
            this.selectedFilters.length > 0 || this.created_at
              ? `&filters=${JSON.stringify(filters)}`
              : ''
          }`
        );

        let statuses = Array.from(new Set(response.data.map(d => d.state)));
        statuses = [...statuses, ...this.getAditionalStatuses];
        this.setStatusInfo(statuses);

        this.pagination = response.pagination || {};

        const markers = response.data.map(o => {
          const statusExists = getStateStyle(o.operation.type, o.state);
          const status = statusExists || getStateStyle(o.operation.type, 'outros');

          statusCount[o.state] = statusCount[o.state] ? statusCount[o.state] + 1 : 1;
          const { longitude, latitude } = o.geolocation;

          return {
            coordinates: [longitude, latitude],
            color: status.color,
            routeOrderNumber: statusCount[o.state],
            statusText: status.label,
            ...o,
          };
        });

        this.markers = markers;
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
      setTimeout(() => {
        this.loading = false;
      }, 300);
    },
    setStatusInfo(status) {
      // const partnerStatus = this.getOperationStatuses;
      const partnerStatus = statusOperationsExtract();

      const statusCollection = [];
      let currentIndexType = 0;

      status.forEach(st => {
        let completeStatus = partnerStatus.find((state, index) => {
          if (state.value === st && st !== 'entregue') {
            return state;
          }
          if (state.value === 'entregue' && index > currentIndexType) {
            currentIndexType = index;
            return state;
          }

          return false;
        });

        if (!completeStatus) {
          completeStatus = partnerStatus.find(state => state.value === 'outros');
        }

        const { label: text, color } = completeStatus;
        statusCollection.push({ text, color, value: completeStatus.value, id: completeStatus.id });
      });

      const data = [...this.statuses, ...statusCollection].map(d =>
        JSON.stringify({ ...d, id: '' })
      );

      const items = Array.from(new Set(data)).map(d => JSON.parse(d));

      this.statuses = items.filter(
        (item, index, self) => index === self.findIndex(t => t.value === item.value)
      );
    },
  },
};
</script>

<style lang="scss">
@import url('https://api.tiles.mapbox.com/mapbox-gl-js/v0.53.0/mapbox-gl.css');

.uello-app-map {
  overflow: hidden;

  // slide-left
  .slide-left-enter-active,
  .slide-left-leave-active {
    transition: all 300ms ease;
  }
  .slide-left-enter {
    transform: translateX(-300px);
  }

  .slide-left-leave-to {
    transform: translateX(300px);
  }
  // slide-map-change
  .slide-map-change-enter-active,
  .slide-map-change-leave-active {
    transition: all 300ms ease;
  }
  .slide-map-change-enter {
    transform: translateX(300px);
  }

  .slide-map-change-leave-to {
    transform: translateX(-300px);
  }

  .change-map-style {
    top: 0;
    right: 0;
    z-index: 12;

    /* .custom-control-label {
      font-size: 12px;
    } */
  }

  .mapboxgl-popup-content {
    padding: 0 !important;

    .mapboxgl-popup-close-button {
      right: 0.2rem;
      top: 0;
      font-size: 16px;
    }
  }

  .mapboxgl-canvas {
    border-radius: 0.25rem !important;
  }

  .mapboxgl-control-container {
    a.mapboxgl-ctrl-logo {
      background: url('../../assets/images/logo-uello-complete-powered-by.png') center center !important;
      background-size: cover !important;
      width: 150px !important;
      height: 40px !important;
    }

    .mapboxgl-ctrl-bottom-right {
      display: none !important;
    }
  }
}
</style>
